import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allAirtable.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" keywords={['blog', 'gatsby', 'javascript', 'react', 'golang', 'go nuggets', 'go programming language', 'node.js']} />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.data.title 
          return (
            <div key={node.data.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: 'none' }} to={node.data.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.data.date}</small>
              <p dangerouslySetInnerHTML={{ __html: node.data.description }} />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allAirtable(sort: { fields: [data___date], order: DESC }) {
      edges {
          node {
            data {
              author
              title
              slug
              description
              date(formatString: "MMMM DD, YYYY")
              status
            }
          }
        }
    }
  }
`
